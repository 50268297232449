import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2><a id="character-classes"></a>Character Classes</h2>
    <h3><a id="monk"></a>MONK</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td className="line"> </td>
        </tr>
      </tbody>
    </table>
    <p className="initial"><a href="http://www.wizards.com/dnd/images/ph35_gallery/PHB35_PG41_WEB.jpg"><img className="art-wide" src="pict.jpg" title="" alt="pict" style={{
          "border": "0px solid",
          "width": "73px",
          "height": "75px"
        }} /></a><b>Alignment</b>:
Any lawful.</p>
    <p><b>Hit Die</b>: d8.</p>
    <h6>Class Skills</h6>
    <p className="initial">The monk&#8217;s class skills (and the key ability
for each skill) are <a href="skillsAll.html#balance" style={{
        "color": "rgb(87, 158, 182)"
      }}>Balance</a>
(Dex), <a href="skillsAll.html#climb" style={{
        "color": "rgb(87, 158, 182)"
      }}>Climb</a>
(Str), <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>Concentration</a> (Con), <a href="skillsAll.html#craft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Craft</a>
(Int), <a href="skillsAll.html#diplomacy" style={{
        "color": "rgb(87, 158, 182)"
      }}>Diplomacy</a> (Cha), <a href="skillsAll.html#escape-artist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Escape
Artist</a> (Dex), <a href="skillsAll.html#hide" style={{
        "color": "rgb(87, 158, 182)"
      }}>Hide</a> (Dex), <a href="skillsAll.html#jump" style={{
        "color": "rgb(87, 158, 182)"
      }}>Jump</a>
(Str), <a href="skillsAll.html#knowledge" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge</a> (arcana/religion)
(Int), <a href="skillsAll.html#listen" style={{
        "color": "rgb(87, 158, 182)"
      }}>Listen</a> (Wis), <a href="skillsAll.html#move-silently" style={{
        "color": "rgb(87, 158, 182)"
      }}>Move
Silently</a> (Dex), <a href="skillsAll.html#perform" style={{
        "color": "rgb(87, 158, 182)"
      }}>Perform</a> (Cha), <a href="skillsAll.html#profession" style={{
        "color": "rgb(87, 158, 182)"
      }}>Profession</a>
(Wis), <a href="skillsAll.html#sense-motive" style={{
        "color": "rgb(87, 158, 182)"
      }}>Sense Motive</a> (Wis), <a href="skillsAll.html#spot" style={{
        "color": "rgb(87, 158, 182)"
      }}>Spot</a>
(Wis), <a href="skillsAll.html#swim" style={{
        "color": "rgb(87, 158, 182)"
      }}>Swim</a>
(Str), and <a href="skillsAll.html#tumble" style={{
        "color": "rgb(87, 158, 182)"
      }}>Tumble</a> (Dex).</p>
    <p><b>Skill Points at 1st Level</b>: (4 + Int modifier) x 4.</p>
    <p><b>Skill Points at Each Additional Level</b>: 4 + Int modifier.</p>
    <p><a id="table-the-monk"></a><b>Table: The Monk</b></p>
    <table border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="bottom">
          <th style={{
            "width": "6%"
          }}>Level</th>
          <th style={{
            "width": "11%"
          }}>Base
Attack Bonus</th>
          <th style={{
            "width": "6%"
          }}>Fort
Save</th>
          <th style={{
            "width": "6%"
          }}>Ref
Save</th>
          <th style={{
            "width": "6%"
          }}>Will
Save</th>
          <th style={{
            "width": "26%"
          }}>Special</th>
          <th style={{
            "width": "15%"
          }}>Flurry of Blows
Attack Bonus</th>
          <th style={{
            "width": "8%"
          }}>Unarmed
Damage<sup>1</sup></th>
          <th style={{
            "width": "6%"
          }}>AC
Bonus</th>
          <th style={{
            "width": "10%"
          }}>Unarmored
Speed Bonus</th>
        </tr>
        <tr className="odd-row">
          <td>1st</td>
          <td>+0</td>
          <td>+2</td>
          <td>+2</td>
          <td>+2</td>
          <td>Bonus feat, flurry of blows unarmed strike</td>
          <td>&#8211;2/&#8211;2</td>
          <td>1d6</td>
          <td>+0</td>
          <td>+0 ft.</td>
        </tr>
        <tr>
          <td>2nd</td>
          <td>+1</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>Bonus feat, evasion</td>
          <td>&#8211;1/&#8211;1</td>
          <td>1d6</td>
          <td>+0</td>
          <td>+0 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>3rd</td>
          <td>+2</td>
          <td>+3</td>
          <td>+3</td>
          <td>+3</td>
          <td>Still mind</td>
          <td>+0/+0</td>
          <td>1d6</td>
          <td>+0</td>
          <td>+10 ft.</td>
        </tr>
        <tr>
          <td>4th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>Ki strike (magic), slow fall 20 ft.</td>
          <td>+1/+1</td>
          <td>1d8</td>
          <td>+0</td>
          <td>+10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>5th</td>
          <td>+3</td>
          <td>+4</td>
          <td>+4</td>
          <td>+4</td>
          <td>Purity of body</td>
          <td>+2/+2</td>
          <td>1d8</td>
          <td>+1</td>
          <td>+10 ft.</td>
        </tr>
        <tr>
          <td>6th</td>
          <td>+4</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
          <td>Bonus feat, slow fall 30 ft.</td>
          <td>+3/+3</td>
          <td>1d8</td>
          <td>+1</td>
          <td>+20 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>7th</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
          <td>+5</td>
          <td>Wholeness of body</td>
          <td>+4/+4</td>
          <td>1d8</td>
          <td>+1</td>
          <td>+20 ft.</td>
        </tr>
        <tr>
          <td>8th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
          <td>Slow fall 40 ft.</td>
          <td>+5/+5/+0</td>
          <td>1d10</td>
          <td>+1</td>
          <td>+20 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>9th</td>
          <td>+6/+1</td>
          <td>+6</td>
          <td>+6</td>
          <td>+6</td>
          <td>Improved evasion</td>
          <td>+6/+6/+1</td>
          <td>1d10</td>
          <td>+1</td>
          <td>+30 ft.</td>
        </tr>
        <tr>
          <td>10th</td>
          <td>+7/+2</td>
          <td>+7</td>
          <td>+7</td>
          <td>+7</td>
          <td>Ki strike, (lawful) slow fall 50 ft.</td>
          <td>+7/+7/+2</td>
          <td>1d10</td>
          <td>+2</td>
          <td>+30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>11th</td>
          <td>+8/+3</td>
          <td>+7</td>
          <td>+7</td>
          <td>+7</td>
          <td>Diamond body, greater flurry</td>
          <td>+8/+8/+8/+3</td>
          <td>1d10</td>
          <td>+2</td>
          <td>+30 ft.</td>
        </tr>
        <tr>
          <td>12th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+8</td>
          <td>+8</td>
          <td>Abundant step, slow fall 60 ft.</td>
          <td>+9/+9/+9/+4</td>
          <td>2d6</td>
          <td>+2</td>
          <td>+40 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>13th</td>
          <td>+9/+4</td>
          <td>+8</td>
          <td>+8</td>
          <td>+8</td>
          <td>Diamond soul</td>
          <td>+9/+9/+9/+4</td>
          <td>2d6</td>
          <td>+2</td>
          <td>+40 ft.</td>
        </tr>
        <tr>
          <td>14th</td>
          <td>+10/+5</td>
          <td>+9</td>
          <td>+9</td>
          <td>+9</td>
          <td>Slow fall 70 ft.</td>
          <td>+10/+10/+10/+5</td>
          <td>2d6</td>
          <td>+2</td>
          <td>+40 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>15th</td>
          <td>+11/+6/+1</td>
          <td>+9</td>
          <td>+9</td>
          <td>+9</td>
          <td>Quivering palm</td>
          <td>+11/+11/+11/+6/+1</td>
          <td>2d6</td>
          <td>+3</td>
          <td>+50 ft.</td>
        </tr>
        <tr>
          <td>16th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+10</td>
          <td>+10</td>
          <td>Ki strike (adamantine), slow fall 80 ft.</td>
          <td>+12/+12/+12/+7/+2</td>
          <td>2d8</td>
          <td>+3</td>
          <td>+50 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>17th</td>
          <td>+12/+7/+2</td>
          <td>+10</td>
          <td>+10</td>
          <td>+10</td>
          <td>Timeless body, tongue of the sun and moon</td>
          <td>+12/+12/+12/+7/+2</td>
          <td>2d8</td>
          <td>+3</td>
          <td>+50 ft.</td>
        </tr>
        <tr>
          <td>18th</td>
          <td>+13/+8/+3</td>
          <td>+11</td>
          <td>+11</td>
          <td>+11</td>
          <td>Slow fall 90 ft.</td>
          <td>+13/+13/+13/+8/+3</td>
          <td>2d8</td>
          <td>+3</td>
          <td>+60 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>19th</td>
          <td>+14/+9/+4</td>
          <td>+11</td>
          <td>+11</td>
          <td>+11</td>
          <td>Empty body</td>
          <td>+14/+14/+14/+9/+4</td>
          <td>2d8</td>
          <td>+3</td>
          <td>+60 ft.</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row">+15/+10/+5</td>
          <td className="last-row">+12</td>
          <td className="last-row">+12</td>
          <td className="last-row">+12</td>
          <td className="last-row">Perfect self, slow fall any distance</td>
          <td className="last-row">+15/+15/+15/+10/+5</td>
          <td className="last-row">2d10</td>
          <td className="last-row">+4</td>
          <td className="last-row">+60 ft.</td>
        </tr>
        <tr>
          <td colSpan="10">1 <i>The value shown is for Medium monks.
See Table: Small or Large Monk Unarmed Damage for Small or Large monks.</i></td>
        </tr>
      </tbody>
    </table>
    <h6><a id="monk-class-features"></a>Class Features</h6>
    <p className="initial">All of the following are class features of the
monk.</p>
    <p><b>Weapon and Armor Proficiency</b>: Monks are proficient with
club, crossbow (light or heavy), dagger, handaxe, javelin, kama,
nunchaku, quarterstaff, sai, shuriken, siangham, and sling. Monks are
not proficient with any armor or shields. When wearing armor, using a
shield, or carrying a medium or heavy load, a monk loses her AC bonus,
as well as her fast movement and flurry of blows abilities.</p>
    <p><a id="monk-ac-bonus"></a><b>AC Bonus (Ex)</b>: When unarmored and unencumbered, the
monk adds her Wisdom bonus (if any) to her AC. In addition, a monk
gains a +1 bonus to AC at 5th level. This bonus increases by 1 for
every five monk levels thereafter (+2 at 10th, +3 at 15th, and +4 at
20th level).</p>
    <p>These bonuses to AC apply even against touch attacks or when
the monk is <a href="abilitiesAndConditions.html#flat-footed" style={{
        "color": "rgb(87, 158, 182)"
      }}>flat-footed</a>. She loses these
bonuses when she is
immobilized or <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a>, when she wears any
armor, when she carries a
shield, or when she carries a medium or heavy load.</p>
    <p><a id="monk-flurry-of-blows"></a><b>Flurry of Blows (Ex)</b>: When unarmored, a monk may strike
with a flurry of blows at the expense of accuracy. When doing so, she
may make one extra attack in a round at her highest base attack bonus,
but this attack takes a &#8211;2 penalty, as does each other attack made that
round. The resulting modified base attack bonuses are shown in the
Flurry of Blows Attack Bonus column on Table: The Monk. This penalty
applies for 1 round, so it also affects attacks of opportunity the monk
might make before her next action. When a monk reaches 5th level, the
penalty lessens to &#8211;1, and at 9th level it disappears. A monk must use
a full attack action to strike with a flurry of blows.</p>
    <p>When using flurry of blows, a monk may attack only with
unarmed strikes or with special monk weapons (kama, nunchaku,
quarterstaff, sai, shuriken, and siangham). She may attack with unarmed
strikes and special monk weapons interchangeably as desired. When using
weapons as part of a flurry of blows, a monk applies her Strength bonus
(not Str bonus x 1-1/2 or x 1/2) to her damage rolls for all successful
attacks, whether she wields a weapon in one or both hands. The monk
can&#8217;t use any weapon other than a special monk weapon as part of a
flurry of blows.</p>
    <p>In the case of the quarterstaff, each end counts as a separate
weapon for the purpose of using the flurry of blows ability. Even
though the quarterstaff requires two hands to use, a monk may still
intersperse unarmed strikes with quarterstaff strikes, assuming that
she has enough attacks in her flurry of blows routine to do so.</p>
    <p>When a monk reaches 11th level, her flurry of blows ability
improves. In addition to the standard single extra attack she gets from
flurry of blows, she gets a second extra attack at her full base attack
bonus.</p>
    <p><a id="unarmed-strike"></a><b>Unarmed Strike</b>: At 1st level, a monk gains Improved
Unarmed Strike as a bonus feat. A monk&#8217;s attacks may be with either
fist interchangeably or even from elbows, knees, and feet. This means
that a monk may even make unarmed strikes with her hands full. There is
no such thing as an off-hand attack for a monk striking unarmed. A monk
may thus apply her full Strength bonus on damage rolls for all her
unarmed strikes.</p>
    <p>Usually a monk&#8217;s unarmed strikes deal lethal damage, but she
can choose to deal nonlethal damage instead with no penalty on her
attack roll. She has the same choice to deal lethal or nonlethal damage
while <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a>.</p>
    <p>A monk&#8217;s unarmed strike is treated both as a manufactured
weapon and a natural weapon for the purpose of spells and effects that
enhance or improve either manufactured weapons or natural weapons.</p>
    <p>A monk also deals more damage with her unarmed strikes than a
normal person would, as shown on Table: The Monk. The unarmed damage on
Table: The Monk is for Medium monks. A Small monk deals less damage
than the amount given there with her unarmed attacks, while a Large
monk deals more damage; see Table: Small or Large Monk Unarmed Damage.</p>
    <p><b>Table: Small or Large Monk Unarmed Damage</b></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "80px"
          }}><a id="table-small-or-large-monk-unarmed-damage"></a>Level</th>
          <th style={{
            "width": "160px"
          }}>Damage (Small Monk)</th>
          <th style={{
            "width": "160px"
          }}>Damage (Large Monk)</th>
        </tr>
        <tr className="odd-row">
          <td>1st&#8211;3rd</td>
          <td align="center">1d4</td>
          <td align="center">1d8</td>
        </tr>
        <tr>
          <td>4th&#8211;7th</td>
          <td align="center">1d6</td>
          <td align="center">2d6</td>
        </tr>
        <tr className="odd-row">
          <td>8th&#8211;11th</td>
          <td align="center">1d8</td>
          <td align="center">2d8</td>
        </tr>
        <tr>
          <td>12th&#8211;15th</td>
          <td align="center">1d10</td>
          <td align="center">3d6</td>
        </tr>
        <tr className="odd-row">
          <td>16th&#8211;19th</td>
          <td align="center">2d6</td>
          <td align="center">3d8</td>
        </tr>
        <tr>
          <td className="last-row">20th</td>
          <td className="last-row" align="center">2d8</td>
          <td className="last-row" align="center">4d8</td>
        </tr>
      </tbody>
    </table>
    <p><b>Bonus Feat</b>: At 1st level, a monk may select either <a href="featsAll.html#improved-grapple" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Grapple</a> or <a href="featsAll.html#stunning-fist" style={{
        "color": "rgb(87, 158, 182)"
      }}>Stunning
Fist</a> as a bonus feat. At 2nd level, she
may select either <a href="featsAll.html#combat-reflexes" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat Reflexes</a> or <a href="featsAll.html#deflect-arrows" style={{
        "color": "rgb(87, 158, 182)"
      }}>Deflect
Arrows</a> as a bonus feat. At
6th level, she may select either <a href="featsAll.html#improved-disarm" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved
Disarm</a> or <a href="featsAll.html#improved-trip" style={{
        "color": "rgb(87, 158, 182)"
      }}>Improved Trip</a> as a
bonus feat. A monk need not have any of the prerequisites normally
required for these feats to select them.</p>
    <p><a id="monk-evasion"></a><b>Evasion (Ex)</b>: At 2nd level or higher if a monk makes a
successful Reflex saving throw against an attack that normally deals
half damage on a successful save, she instead takes no damage. Evasion
can be used only if a monk is wearing light armor or no armor. A <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> monk does not gain the
benefit of evasion.</p>
    <p><a id="monk-fast-movement"></a><b>Fast Movement (Ex)</b>: At 3rd level, a monk gains an
enhancement bonus to her speed, as shown on Table: The Monk. A monk in
armor or carrying a medium or heavy load loses this extra speed.</p>
    <p><a id="monk-still-mind"></a><b>Still Mind (Ex)</b>: A monk of 3rd level or higher gains a
+2 bonus on saving throws against spells and effects from the school of
enchantment.</p>
    <p><a id="monk-ki-strike"></a><b>Ki Strike (Su)</b>: At 4th level, a monk&#8217;s unarmed attacks
are empowered with ki. Her unarmed attacks are treated as magic weapons
for the purpose of dealing damage to creatures with damage reduction.
Ki strike improves with the character&#8217;s monk level. At 10th level, her
unarmed attacks are also treated as lawful weapons for the purpose of
dealing damage to creatures with damage reduction. At 16th level, her
unarmed attacks are treated as adamantine weapons for the purpose of
dealing damage to creatures with damage reduction and bypassing
hardness.</p>
    <p><a id="monk-slow-fall"></a><b>Slow Fall (Ex)</b>: At 4th level or higher, a monk within
arm&#8217;s reach of a wall can use it to slow her descent. When first using
this ability, she takes damage as if the fall were 20 feet shorter than
it actually is. The monk&#8217;s ability to slow her fall (that is, to reduce
the effective distance of the fall when next to a wall) improves with
her monk level until at 20th level she can use a nearby wall to slow
her descent and fall any distance without harm.</p>
    <p><a id="monk-purity-of-body"></a><b>Purity of Body (Ex)</b>: At 5th level, a monk gains
immunity to all diseases except for supernatural and magical diseases.</p>
    <p><a id="monk-wholeness-of-body"></a><b>Wholeness of Body (Su)</b>: At 7th level or higher, a monk
can heal her own wounds. She can heal a number of hit points of damage
equal to twice her current monk level each day, and she can spread this
healing out among several uses.</p>
    <p><a id="monk-improved-evasion"></a><b>Improved Evasion (Ex)</b>: At 9th level, a monk&#8217;s evasion
ability improves. She still takes no damage on a successful Reflex
saving throw against attacks, but henceforth she takes only half damage
on a failed save. A <a href="abilitiesAndConditions.html#helpless" style={{
        "color": "rgb(87, 158, 182)"
      }}>helpless</a> monk does not gain the
benefit of improved
evasion.</p>
    <p><a id="monk-diamond-body"></a><b>Diamond Body (Su)</b>: At 11th level, a monk gains immunity
to poisons of all kinds.</p>
    <p><a id="monk-abundant-step"></a><b>Abundant Step (Su)</b>: At 12th level or higher, a monk can
slip magically between spaces, as if using the spell dimension door,
once per day. Her caster level for this effect is one-half her monk
level (rounded down).</p>
    <p><a id="monk-diamond-soul"></a><b>Diamond Soul (Ex)</b>: At 13th level, a monk gains spell
resistance equal to her current monk level + 10. In order to affect the
monk with a spell, a spellcaster must get a result on a caster level
check (1d20 + caster level) that equals or exceeds the monk&#8217;s spell
resistance.</p>
    <p><a id="monk-quivering-palm"></a><b>Quivering Palm (Su)</b>: Starting at 15th level, a monk can
set up vibrations within the body of another creature that can
thereafter be fatal if the monk so desires. She can use this quivering
palm attack once a week, and she must announce her intent before making
her attack roll. Constructs, oozes, plants, undead, <a href="abilitiesAndConditions.html#incorporeal" style={{
        "color": "rgb(87, 158, 182)"
      }}>incorporeal</a>
creatures, and creatures immune to critical hits cannot be affected.
Otherwise, if the monk strikes successfully and the target takes damage
from the blow, the quivering palm attack succeeds. Thereafter the monk
can try to slay the victim at any later time, as long as the attempt is
made within a number of days equal to her monk level. To make such an
attempt, the monk merely wills the target to die (a free action), and
unless the target makes a Fortitude saving throw (DC 10 + 1/2 the
monk&#8217;s level + the monk&#8217;s Wis modifier), it dies. If the saving throw
is successful, the target is no longer in danger from that particular
quivering palm attack, but it may still be affected by another one at a
later time.</p>
    <p><a id="monk-timeless-body"></a><b>Timeless Body (Ex)</b>: Upon attaining 17th level, a monk
no longer takes penalties to her ability scores for aging and cannot be
magically aged. Any such penalties that she has already taken, however,
remain in place. Bonuses still accrue, and the monk still dies of old
age when her time is up.</p>
    <p><a id="monk-tongue-of-the-sun-and-moon"></a><b>Tongue of the Sun and Moon (Ex)</b>: A monk of 17th level
or higher can speak with any living creature.</p>
    <p><a id="monk-empty-body"></a><b>Empty Body (Su)</b>: At 19th level, a monk gains the
ability to assume an ethereal state for 1 round per monk level per day,
as though using the spell etherealness. She may go ethereal on a number
of different occasions during any single day, as long as the total
number of rounds spent in an ethereal state does not exceed her monk
level.</p>
    <p><a id="monk-perfect-self"></a><b>Perfect Self</b>: At 20th level, a monk becomes a magical
creature. She is forevermore treated as an outsider rather than as a
humanoid (or whatever the monk&#8217;s creature type was) for the purpose of
spells and magical effects. Additionally, the monk gains damage
reduction 10/magic, which allows her to ignore the first 10 points of
damage from any attack made by a nonmagical weapon or by any natural
attack made by a creature that doesn&#8217;t have similar damage reduction.
Unlike other outsiders, the monk can still be brought back from the
dead as if she were a member of her previous creature type.</p>
    <h6><a id="ex-monks"></a>Ex-Monks</h6>
    <p className="initial">A monk who becomes nonlawful cannot gain new
levels as a monk but retains all monk abilities.</p>
    <p>Like a member of any other class, a monk may be a multiclass
character, but multiclass monks face a special restriction. A monk who
gains a new class or (if already multiclass) raises another class by a
level may never again raise her monk level, though she retains all her
monk abilities.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      